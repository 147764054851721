
		export default {
			staticResourcesBucketUrl: 'https://s3-eu-central-1.amazonaws.com/locbeedemo-staticresourcess3bucket-psvja5f063py',
			documentStoreBucketUrl: 'https://s3-eu-central-1.amazonaws.com/locbeedemo-documentstores3bucket-1ww7ekes5nm1y',
			apiGatewayBaseUrl: 'https://nb949mvb1h.execute-api.eu-central-1.amazonaws.com/Prod',
			realtimeTrackingWebSocketUri: 'wss://4zjkpij9q1.execute-api.eu-central-1.amazonaws.com/Prod',
			languageCodes: 'hu,en'.split(','),
			onesignalAuthToken: 'Basic YWExZjY4NGEtNTYzZS00NjZhLWEzMDItZDVjNGZmMDljZGUy',
			onesignalAppId: 'a321381d-40c8-41ed-937c-f011db1128a3',
			statTypes: '{"data":["heatmap","real-time","active-users-monthly","active-users-daily","total-users","total-zone-visits-monthly","total-zone-visits","dwell-time-by-dow","asked-per-answered-quiz","right-per-wrong-quiz","rating-value","push-sent","push-opened","treasure_hunt-gamers","asked-per-answered-treasure_hunt"]}'
		}
	
